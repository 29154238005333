// import React from "react";
export const MenuName = [
  {
    _id: 1,
    urlName: "matches",
    navName: "MATCHES",
  },
  {
    _id: 2,
    urlName: "profile",
    navName: "PROFILE",
  },
  {
    _id: 3,
    urlName: "statement",
    navName: "STATEMENT",
  },
  {
    _id: 4,
    urlName: "Change_password",
    navName: "PASSWORD",
  },
  {
    _id: 5,
    urlName: "freegames",
    navName: "GAMES",
  },
  {
    _id: 6,
    urlName: "rules",
    navName: "RULES",
  },
  {
    _id: 7,
    urlName: "Ledgers",
    navName: "LEDGERS",
  },
  {
    _id: 8,
    urlName: "games",
    navName: "GAMES",
  },
  {
    _id: 9,
    urlName: "",
    navName: "RULES",
  },
  {
    _id: 9,
    urlName: "dashboard",
    navName: "DASHBOARD",
  },
  

  {
    _id: 10,
    urlName: "UserLiveBetHistory",
    navName: "UserLiveBetHistory",
  },
  {
    _id: 11,
    urlName: "MyCommission",
    navName: "MyCommission",
  },
  {
    _id: 12,
    urlName: "UserProfitLoss",
    navName: "UserProfitLoss",
  },
  {
    _id: 13,
    urlName: "UserBetHistory",
    navName: "UserBetHistory",
  },

  {
    _id: 14,
    urlName: "setting",
    navName: "setting",
  },
  
];
