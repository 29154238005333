import React from 'react'
import { Link } from 'react-router-dom'
import authAxios from '../../services/authAxios'
import common from '../../services/comman'
import baseURL from '../../services/baseUrl'
import { useEffect } from 'react'
import { useState } from 'react'

const UserMyLedger = () => {
    

    
  return (
      <>
        <section>
            <div className="container"> 
                <div className="tableScroll">
                <table>
                    <thead>
                        <tr className="secHeading">
                            <th>Date</th>
                            <th>Collaction Name</th>
                            <th>Dabit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                            <th>Payment Type</th>
                            <th>Remark</th> 
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td> 09 Jan 23 </td>
                        <td> Mobile Charge (Hobart Hurricanes v Melbourne Stars) `</td>
                        <td align="center">9473.02 </td>
                        <td align="center">0</td>
                        <td align="center">0</td>
                        <td align="center">0</td>
                        <td align="center">9473.02</td>
                    </tr>
                    </tbody>
                </table> 
                </div>
                <Link to="/dashboard" className="backBtn"> back to main menu </Link>
            </div>
        </section>
      </>
  )
}

export default UserMyLedger