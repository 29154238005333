import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom' 
import authAxios from '../services/authAxios';
import baseURL from '../services/baseUrl';
import common from '../services/comman';
import { useState } from 'react';
import { useEffect } from 'react';

const CompletedBets = () => {
    const { Matchid, eventId, marketId } = useParams(); 
    const { state } = useLocation()
    // console.log(Matchid, eventId, marketId)
    
    const [matchDetail, setMatchDetail] = useState([]) 
    const [sessionPlusMinus, setSessionPlusMinus] = useState() 
    
    const GetMatchD = ((values) => {
        // console.log(values)

        authAxios({
            method: "POST",
            url: `${baseURL}/AccountDetails/GetLedgerMatchDetails`,
            data: values
        }).then(function (res) { 
            setSessionPlusMinus(res?.data?.data)
            // console.log(res?.data?.data) 
            let temp = [] 
            res?.data?.data?.sessionBetsDataList?.map((d, k) => {
                if (d?.resultScore != "") {
                    temp.push(d)
                    setMatchDetail(temp)
                    // console.log(temp)
                }
                return d
            }) 
        }).catch(function (error) {
            common.error(error);
        })
    })
    useEffect(() => {
        if (Matchid) {
            let postData = { MatchId: Matchid || "" }
            GetMatchD(postData) 
        }
    }, [Matchid]) 

    
    return (
    <>
        <section className='completedBetWrap'>
                {/* <div className='container'> </div> */}
                <div className='cfb'> completed fancy bets </div>
                <div className='tableScroll'>
                    <table>
                        <thead>
                            <tr>
                                <th>Runner</th>
                                <th>Date</th>
                                <th>Run</th>
                                <th>Rate</th>
                                <th>Result</th>
                                <th>Amount</th>
                                <th>Mode</th>
                                <th>P&L</th>
                            </tr>
                        </thead>
                        <tbody>
                            {  matchDetail?.map((d, k) => ( 
                            <tr key={k}>
                            {/* {console.log(d)} */}
                                <td>{d?.match}</td>
                                <td>{d?.date}</td>
                                <td>{d?.rate}</td>
                                <td>{d?.line}</td>
                                <td>{d?.resultScore}</td>
                                <td>{d?.amount}</td>
                                <td>{d?.betType}</td>
                                <td className={`${d?.pl?.toString()?.charAt(0) === "-" ? "red" : "blue"}`}>{d?.pl}</td>
                            </tr>
                            )) } 

                        {sessionPlusMinus?.sessionBetsDataList?.length === 0 ?
                            <tr>
                                <td colSpan='8' style={{padding: '8px 0', }}> No Bets Available </td>
                            </tr> 
                        : "" } 

                        {sessionPlusMinus?.sessionPlusMinus == 0 ? " " : 
                            <tr>
                                <td colSpan="7">Total Plus Minus</td>          
                                <td className={`${sessionPlusMinus?.sessionPlusMinus?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}> {sessionPlusMinus?.sessionPlusMinus}</td>
                            </tr> 
                        }
                        
                        
                        
                        </tbody>
                    </table>
                </div>
                <Link to={`/sportGame/${Matchid}/${eventId}/${marketId}`} state={state} className="backMatch"> BACK TO MATCH </Link>  
                {/* <Link to={`/sportGame/${Matchid}`} className="backMatch"> BACK TO MATCH </Link>   */}
            
        </section>
    </>
    )
}

export default CompletedBets