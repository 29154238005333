import React, { useContext, useEffect, useState } from "react"; 
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup"; 
import unAuthAxios from "../../services/unAuthAxios";
import common from "../../services/comman";
import { UserContext } from "../../context/loginContext";
import { publicIp } from "public-ip"; 

import "./login.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import baseURL from "../../services/baseUrl";

const LoginClient = () => {
  const { setLogin, setWelModal, setUserData } = useContext(UserContext);
  const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState();
  const [errorPopup, setErrorPopup] = useState(false);
  
  
  // function convertObjectToString(obj) {
  //   let result = '{';
  //   for (let key in obj) {
  //       if (obj.hasOwnProperty(key)) {
  //           result += `"${key}": "${String(obj[key])}", `;
  //       }
  //   } 
  //   result = result.slice(0, -2) + "}";
  //   return result;
  // }
  
  const validationSchema = yup.object({
    UserName: yup.string().required(""),
    Password: yup.string().required(""),
  });
  const formik = useFormik({
    initialValues: {
      UserName: "",
      Password: "",
    },
    validationSchema,
    onSubmit: async (values) => { 
      let loginData = { 
        UserName: String(values.UserName),  
        Password : String(values.Password), 
        IpAddress :  "1.1.1.1", 
      };  
      login(loginData);
    },  
  }); 
  const login = (payload) => {  
    // let formattedString = JSON.stringify(payload);
    setLoader(false);   
    unAuthAxios({
      // axios({
      method: "POST",
      url: `${baseURL}/Authenticate/Login`,
      data: payload,
    }).then(function (response) {
        if (response.data.status == 0) {
          setTimeout(() => {
            setLogin(true);
          }, 1000); 
          setWelModal(true);
          let resData = response?.data || {}; 
          setUserData(resData);
          localStorage.setItem("name", resData?.name);
          localStorage.setItem("token", resData?.token);
          localStorage.setItem("userType", resData?.userType);
          localStorage.setItem("sessionId", resData?.sessionId);  
        } else if (response.data.status == 1) { 
          // toast.error(response?.data?.message);  
          setMessage(response?.data?.message) 
          formik.resetForm();
          setLoader(false);   
          setErrorPopup(true);
          setTimeout(() => {
            setLoader(true);
          }, 1000); 
        }
      }).catch(function (error) {
        console.log(error)
        common.error(error);
      });
  }; 

  useEffect(() => {
    if (errorPopup == true) {
      setTimeout(() => {
        setErrorPopup(false);  
      }, 3000); 
    } 
  },[errorPopup])

  const pageReload = () => {
    window.location.reload();
  }

  
  

  return (
    <>
      {/* <ToastContainer /> */}
      <section className="bgBack">
        <div className={errorPopup == true ? "errorMes active" : "errorMes"}>
          <i className="fas fa-times"></i><span className="content">{message}</span>
          {/* {errorPopup == true ? <><i className="fas fa-times"></i><span className="content">{message}</span></> : ""} */}
        </div>
        <div className="loginOuter">
          <div className="loginLeft">
            <img src="/images/radhe.png" alt="" />
          </div>
          <div className="loginRight">
            <div className="formcontent">
              <h6> Please Login To Continue</h6>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <input type="text" placeholder="Username" {...formik.getFieldProps("UserName")} />
                  {formik.touched.UserName && formik.errors.UserName ? (<div>{formik.errors.UserName}</div>) : null}
                </div>
                <div className="form-group">
                  <input type="password" placeholder="Password" {...formik.getFieldProps("Password")} />
                  {formik.touched.Password && formik.errors.Password ? ( <div>{formik.errors.Password}</div>) : null}
                </div>
                <div className="form-button">
                  <button type="submit" className="btn login_btn"> 
                    {loader == true ? <>Login
                      <i className="fa fa-sign-in"></i> </> : (<i className="fas fa-circle-notch fa-spin"></i>)
                    } 
                  </button>
                </div>
              </form>
              {/* <i className="fa-solid fa-right-to-bracket"></i> </> : (<i className="fas fa-circle-notch fa-spin"></i>) */}
            
              <ul className="privacyPolicy">
                <li onClick={() => pageReload('index')}><Link to='/'> Privacy Policy </Link> </li>
                <li onClick={() => pageReload('index')}><Link to='/'> Terms & Conditions </Link>  </li>
                <li onClick={() => pageReload('index')}><Link to='/'>  Rules & Regulations </Link></li>
              </ul> 
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginClient;