    // const baseURL = "https://api.bigdaddyexch.co/api";
    // const baseURL = "https://api.hubs98.com/api";
    // const baseURL = "https://api.radhe99.in/api"; 

    // const baseURL = "https://api.betbook12.com/api";  
    const baseURL = "https://api.betcric12.com/api";  
    const socketConnectionURL = "https://api.antpro99.in";

    export{socketConnectionURL}
    export default baseURL;
