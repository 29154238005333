import React from 'react' 
import baseURL from '../../services/baseUrl';
import authAxios from '../../services/authAxios';
import common from '../../services/comman';
import { useState, useEffect } from 'react'; 
import DatePicker from "react-datepicker";
import { useFormik } from 'formik';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';


const UserProfitLoss = () => {
  const[abortcontroll, setAbortControl]=useState("")   

  const sevdayPre = new Date(common.minuDay(new Date(), 15))
  const prevesDate = new Date(sevdayPre) 
  const [userPL, setUserPL] = useState()
  const [startDate, setStartDate] = useState(prevesDate)
  const [endDate, setEndDate] = useState(new Date())
  const [market, setMarket] = useState()
  /**/ 
  const [currentPage, setCurrentPage] = useState(0);
  

  const getMarketType = (eStates) => {
    setMarket(eStates.target.value)
  } 

  const initialValues = {
    FromDate: moment(startDate).format("MM/DD/YYYY") || "",
    ToDate: moment(endDate).format("MM/DD/YYYY") || "",
    Market: market ? market.toString() : ""
  } 
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => { 
      values.FromDate = startDate ? moment(startDate).format("MM/DD/YYYY") : ""
      values.ToDate = endDate ? moment(endDate).format("MM/DD/YYYY") : ""
      values.Market = market ? market.toString() : "All" 
      GetProfitLossReport(values)
    }
  }) 
  /* == */
  const GetProfitLossReport = (values) => {  
    authAxios({
      method: "POST",
      url: `${baseURL}/AccountDetails/GetUserProfitLoss`,
      data: values
    }).then(function (res) { 
      setUserPL(res?.data?.data); 
    }).catch(function(error){
      common.error(error);
    })
  }
  useEffect(() => {
    setAbortControl(true);
      let postData = {FromDate: "",ToDate: "",Market : "All"}
      
      if(abortcontroll){
        GetProfitLossReport(postData)
      } 
      return () => {
        setAbortControl({}); 
      } 

  },[abortcontroll])

  /* == */  
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const itemsPerPage = 10;
  const startIndex = currentPage * itemsPerPage; 
  let currentItems = userPL?.userProfitLossDataLists.slice(startIndex, startIndex + itemsPerPage); 
  
  // useEffect(() => { 
  // },[startIndex])
  return (
    <>
      <section className="userBetHistory">
        <h6 className="headingOne  "> profit loss listing </h6>
        <div className="container">  
          <form onSubmit={formik.handleSubmit}>
            <div className='filterWrap'>
              <DatePicker placeholder="mm/dd/yyyy" selected={startDate} onChange={((date) => {setStartDate(date)})} />
              <DatePicker placeholder="mm/dd/yyyy" selected={endDate} onChange={((date) => {setEndDate(date)})} />
              <select onChange={(eStates) => getMarketType(eStates)}>
                <option value="All">All </option>
                <option value="t20">Live T20</option>
                <option value="ab2">Andar bahar</option>
                <option value="ONEDAY">One Day</option>
                <option value="lucky7">7 up &amp; Down</option>
                {/* <option value="32carda">32 cards casino</option> */}
                {/* <option value="dt20">Dragon Tiger</option> */}
                <option value="test">Test Teenpatti</option>
              </select>
              <button type='submit'><i className="fa fa-filter"></i> Filter </button>
            </div>
          </form> 
          <div className="tableScroll">
            <table className="mb-3">
              <thead> 
                <tr className="secHeading">
                  <th>Sr.Name	</th>
                  <th>EventName</th>
                  <th>Market</th>
                  <th>P_L</th>
                  <th>Commision</th>
                  <th>CreateOn</th> 
                </tr>
              </thead>
              <tbody>
                {/* {userPL?.userProfitLossDataLists?.map((d, k) => (  */}
                {currentItems?.map((d, k) => (  
                  <tr key={k}> 
                    <td align='center'>{k + 1}</td> 
                    <td>{d?.description}</td>
                    <td>{d?.market}</td>
                    <td align='center'>{d?.profitLoss}</td>
                    <td align='center'>{d?.commission}</td>
                    <td>{d?.date}</td>  
                  </tr>
                ))}
              </tbody>
            </table> 
          </div> 
        </div> 

        {/**/}
        <div className='my-3'> 
          <ReactPaginate 
            pageCount={Math.ceil(userPL?.userProfitLossDataLists?.length / itemsPerPage)}
            onPageChange={handlePageChange}
            initialPage={currentItems}
            breakClassName="ellipsis"
            containerClassName="pagination"
            pageClassName="page-item"
            activeClassName="active"
          />  
        </div> 
        <Link to="/dashboard" className="backBtn"> back to main menu </Link>
      </section>
    </>
  )
}

export default UserProfitLoss