import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import authAxios from "../services/authAxios";
import baseURL from "../services/baseUrl";
import common from "../services/comman";
// import { io } from "socket.io-client";
import axios from "axios";
export const UserContext = createContext();


export const UserProvider = ({ children }) => {
  const { Matchid, eventId, marketId } = useParams();
  // console.log('Matchid111', Matchid)

  const history = useLocation()
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [welModal, setWelModal] = useState(false);
  const [balance, setBalance] = useState({});
  const [globalOdds, setGlobalOdds] = useState({});
  const [betSlipModal, setBetslipModal] = useState(false);
  const [betSlips, setBetSlips] = useState([]);
  const [betSlipData, setBetSlipData] = useState({}); 
  const [oddsBetList, setOddsBetList] = useState([]);
  const [tossBetList, setTossBetList] = useState([]);
  const [bookBetList, setBookBetList] = useState([]);
  const [sessionBetList, setSessBetList] = useState([]); 
  const [allCricketData, setAllCricketData] = useState({}); 
  const [aLLSessions, setALLSessions] = useState([]); 
  const [activeSessions, setActiveSessions] = useState([]); 
  const [betSuccessPop, setBetSuccessPop] = useState(false);
  const[scoreId, setScoreId]= useState()
  const [mDetailHide, setMDetailHide] = useState({
    boolean: true,
    matchId: "",
    eventId: "",
    marketId: "",
  })
  const[globleBookOdds, setGlobleBookOdds] = useState()
  const [matchListTest, setMatchListTest] = useState({ matchListArray: [],  }); 
  
  // const [socket, setSocket] = useState();
  const [oldSessionData, setOldSessionData] = useState({ session: null });
  



  // useEffect(() => { 
  //   const connection = io(`${socketConnectionURL}`, { transports: ["websocket"]})
  //   connection.emit("joinRoom", {userId: connection.id}); 
  //   setSocket(connection)
  //   return () => {
  //     connection.disconnect();
  //   }; 
  // },[socketConnectionURL])

  const getOddsBook = async(marketId, eventId) =>{
    try {
      const {data} = await axios({
        method:'GET',
        url:`https://bookmakerapi.cuprate.in/api/MatchOdds/GetOddslite/4/${marketId}/${eventId}`
      })
      let marketData = data?.market?.length > 0 ? data : {
        market:{
          event:[]
        }
      }
      localStorage.setItem('bookOddsData', JSON.stringify(marketData))
      // console.log(data);
      setAllCricketData(marketData) 
      // return data
    } catch (error) {
      console.log(error);
    }
  }
  const getOdds = async(marketId, eventId) =>{
    try {
      const {data} = await axios({
        method:'GET',
        url:`https://zio.funfairexch.com/api/MatchOdds/GetOddslite/4/${marketId}/${eventId}`
      })
      // console.log(data);
      // console.log(data);
      // let marketData = data?.market?.length > 0 ? data : {
      //   market:{
      //     event:[]
      //   }eew
      // }
      let marketData = data;
      localStorage.setItem('sessionOddsData', JSON.stringify(marketData))
      setALLSessions(marketData) 
      // return data
    } catch (error) {
      console.log(error);
    }
  }
  const getSessions = async(marketId) =>{
    try {
      const {data} = await authAxios({
        method:'POST',
        url:`${baseURL}/Player/GetActiveSession`,
        data:{ Id: marketId }
      })
      localStorage.setItem('activeSessionLocal', JSON.stringify(data?.data || []))
      setActiveSessions(data?.data || [])
      // return data
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    // getOdds()
  },[])
  // useEffect(()=>{
  //   if(socket){
  //     socket.on("listenOdds", (data) => { 
  //         localStorage.setItem('sessionOddsData', JSON.stringify(data))
  //         setALLSessions(data)
  //     });
  //     socket.on("listenSession", (data) => {
  //       localStorage.setItem('activeSessionLocal', JSON.stringify(data?.data || []))
  //         setActiveSessions(data?.data || [])
  //     });
      
  //     // socket.on("listenBookOdds", (data) => { 
  //   //   socket.on("listenBookOddsByCuprate", (data) => {
  //   //     // console.log('data', data)
      
  //   //   data = { ...data };
  //   //   if (Array.isArray(data.market)) {
  //   //     data.market.forEach(market => {
  //   //         if (Array.isArray(market.events)) { 
  //   //             const selection3Events = [];  
  //   //             const selection2Events = [];
  //   //             const otherEvents = [];
  //   //             market.events.forEach(event => {
  //   //                 if (event.SelectionId === "3") {
  //   //                     selection3Events.push(event);
  //   //                 }
  //   //                 else if(event.SelectionId === "2"){
  //   //                     selection2Events.push(event);
  //   //                 }
  //   //                 else {
  //   //                     otherEvents.push(event);
  //   //                 }
  //   //             }); 
  //   //             market.events = [...otherEvents, ...selection2Events, ...selection3Events];
  //   //         }
  //   //     });
  //   //   }
  //   //     localStorage.setItem('bookOddsData', JSON.stringify(data))
  //   //   setAllCricketData(data) 

  //   //     // localStorage.setItem('bookOddsData', JSON.stringify(data))
  //   //     // setAllCricketData(data)
  //   // });
  //   }
  // },[socket])


  const logout = async () => {
    await authAxios({
      method: "GET",
      url: `${baseURL}/User/Logout`,
    })
      .then(function (response) { 
        navigate("/");
      })
      .catch(function (error) {
        common.error(error); 
      });
  };
  const reset = () => {
    setLogin(false);
    setBetslipModal(false);
    setBetSlipData({});
    setBetSlips([]);
    setUserData({}); 
    logout(); 
    setOddsBetList([]);
    setTossBetList([]);
    setBookBetList([]);
    setSessBetList([]); 
    setBalance({});
    localStorage.clear();
    setGlobalOdds({}); 
    setBetSuccessPop(); 
    setMatchListTest({});
    setScoreId({});
    // setSocket();
    setOldSessionData({ session: null });
  };
  function BalanceData(id) {
    // console.log('id', id)
    let postData = { sessionId: id };
    authAxios({
      method: "POST",
      url: `${baseURL}/User/GetBalance`,
      data: postData,
    }).then(function (responce) { 
        if(responce?.data?.status === 1){
          reset();
        }else{
          let resData = responce?.data?.data || {};
          setBalance(resData);
        }
      }).catch(function (error) {
        common.error(error);
      }); 
  } 
  useEffect(() => {
    let authLocal = localStorage.getItem("token");
    let userType = localStorage.getItem("userType"); 
    let name = localStorage.getItem("name");
    let sessionId = localStorage.getItem("sessionId");
    let profData = {
      userType: userType,
      sessionId: sessionId,
      name: name,
    };
    setUserData(profData);
    setLogin(authLocal);
  }, []);

  useEffect(() => {
    if (isLogin && userData?.sessionId) {
      BalanceData(userData?.sessionId);
    } 
    

    let intrVal;
    if (isLogin) {
      intrVal = setInterval(() => {
        userData?.sessionId && BalanceData(userData?.sessionId)
      }, 5000);
    }
    return () => clearInterval(intrVal);
  }, [isLogin, userData]);  
  

  // useEffect(() => {
  //   if(userData?.sessionId == null){
  //     setLogin(false)
  //   }
  // },[userData])
  

  let authLocal = localStorage.getItem("token");
  useEffect(() => { 
      let intrVal;
      intrVal = setInterval(() => {
        if(authLocal){
          setLogin(true); 
        }else{
          setLogin(false); 
        }
      }, 1000);
      return () => clearInterval(intrVal);
  }, [authLocal, isLogin]);

  const getOdsHistory = async (Matchid) => {
    if(Matchid){
      let postData = { "Id": Matchid.toString() } 
      await authAxios({
        method: "POST",
        url: `${baseURL}/Player/GetOddsBetList`,
        data: postData,
      })
      .then(function (response) {
          setOddsBetList(response?.data?.data  || [])
      })
      .catch((error) => {
        common.error(error);
      })
    }
  } 

  const getBookHistory = async (Matchid) => {  
    if(Matchid){ 
      let postData = { "Id": Matchid.toString() } 
      await authAxios({
          method: "POST",
          url: `${baseURL}/Player/GetBookBetList`,
          data: postData,
      }).then(function (response) {
          const bookBetList = response?.data?.data || 0
          setBookBetList(bookBetList)  
      }).catch((error) => {
          common.error(error);
      })
    }
  }
  const getSessionHistory = async (Matchid) => { 
    if(Matchid){
      let postData = {
          "Id": Matchid.toString() ? Matchid.toString() : ''
      }  
      await authAxios({
          method: "POST",
          url: `${baseURL}/Player/GetSessionBetList`,
          data: postData,
      }).then(function (response) {
          setSessBetList(response?.data?.data) 
      }).catch((error) => {
          common.error(error);
      })
    }
  }
  const getTossHistory = async (Matchid) => { 
    if(Matchid){
      let postData = {"Id": Matchid.toString()} 
      await authAxios({
          method: "POST",
          url: `${baseURL}/Player/GetTossBetList`,
          data: postData,
      }).then(function (response) {
        setTossBetList(response?.data?.data )
      }).catch((error) => {
          common.error(error);
      })
    }
  } 


  useEffect(() => {
    if (Matchid && betSlipModal) {
        
        // getOdsHistory(Matchid)
        getBookHistory(Matchid)
        getSessionHistory(Matchid)
        getTossHistory(Matchid)
    }
  },[Matchid, betSlipModal])


  const GetDashboard = async () => { 
    try {
      let commData = { SportId: "4" };
      const resMatchList =  await authAxios({
            method: "POST",
            url: `${baseURL}/DashBoard/GetDashboard`,
            data: commData,
      })
      let resData = resMatchList?.data?.data?.sportMathes || {};
      let newArr = [];
      newArr = resData[0]?.mathes?.map((mData, mkey) => {
        let matchListArray = { back0: "", back1: "", back2: "", bookMakerStatus: "", competition: "", date: "", eventId: "", inPlay: "", lay0: "", lay1: "", lay2: "", mType: "", marketId: "", marketType: "", match: "", matchID: "", oddsStatus: "", sessionStatus: "", sport: "", sportID: "", team1: "", team2: "", tvurl: "",  }; 
        
        matchListArray.back0 = mData?.back0 || 0;
        matchListArray.back1 = mData?.back1 || 0;
        matchListArray.back2 = mData?.back2 || 0;
        matchListArray.bookMakerStatus = mData?.bookMakerStatus || 0;
        matchListArray.competition = mData?.competition || 0;
        matchListArray.date = mData?.date || 0;
        matchListArray.eventId = mData?.eventId || 0;
        matchListArray.inPlay = mData?.inPlay || 0;
        matchListArray.lay0 = mData?.lay0 || 0;
        matchListArray.lay1 = mData?.lay1 || 0;
        matchListArray.lay2 = mData?.lay2 || 0;
        matchListArray.mType = mData?.mType || 0;
        matchListArray.marketId = mData?.marketId || 0;
        matchListArray.marketType = mData?.marketType || 0;
        matchListArray.match = mData?.match || 0;
        matchListArray.matchID = mData?.matchID || 0;
        matchListArray.oddsStatus = mData?.oddsStatus || 0;
        matchListArray.sessionStatus = mData?.sessionStatus || 0;
        matchListArray.sport = mData?.sport || 0;
        matchListArray.sportID = mData?.sportID || 0;
        matchListArray.team1 = mData?.team1 || 0;
        matchListArray.team2 = mData?.team2 || 0;
        matchListArray.tvurl = mData?.tvurl || 0; 
        return matchListArray
      }) || [] 
      
      setMatchListTest((prev) => (
        {...prev, matchListArray: newArr}
      )); 

    } catch (error) {
      common.error(error); 
    }
  }
  
  useEffect(() => { 
    if(history?.pathname?.includes('/matches') && isLogin == true){
      // console.log('isLogin', isLogin)
      // GetDashboard();
      GetDashboard();
      
      // if () { 
        // const intrVal = setInterval(() => { 
        // }, 1000);
        // return () => clearInterval(intrVal)
      // } 

    }
  }, [isLogin, history]);
  
  useEffect(() =>{
    if(!history?.pathname?.includes('/sportGame/')){
      setOldSessionData({session: null})
      localStorage.removeItem('sessionOddsData')
    }
  },[history])


  return (
    <UserContext.Provider
      value={{
        isLogin, setLogin, reset,
        userData, setUserData,
        welModal, setWelModal,
        balance, setBalance,
        globalOdds, setGlobalOdds,
        betSlipModal, setBetslipModal,
        betSlips, setBetSlips,
        betSlipData, setBetSlipData,
        matchListTest, setMatchListTest, 
        getOdds,
        getOddsBook,
        getSessions,
        getOdsHistory, 
        getTossHistory,
        getBookHistory,
        getSessionHistory, 

        oddsBetList, setOddsBetList,
        tossBetList, setTossBetList,
        bookBetList, setBookBetList,
        sessionBetList, setSessBetList, 
        betSuccessPop, setBetSuccessPop,
        mDetailHide, setMDetailHide,
        globleBookOdds, setGlobleBookOdds,
        scoreId,setScoreId,
        // socket, setSocket,
        oldSessionData, setOldSessionData,
        activeSessions, setActiveSessions,
        aLLSessions, setALLSessions,
        allCricketData, setAllCricketData
      }}
    >
    {children}
    </UserContext.Provider>
  );
};
