import React, { useEffect, useState } from 'react'
import authAxios from '../../services/authAxios'
import common from '../../services/comman'
import baseURL from '../../services/baseUrl'

import DatePicker from "react-datepicker";
import * as yup from "yup";
import { useFormik } from 'formik';
import moment from 'moment';
import { Link } from 'react-router-dom';

const MyCommission = () => {
  const sevdayPre = new Date(common.minuDay(new Date(), 7))
  const nowDate = new Date() 
  const prevesDate = new Date(sevdayPre)

  const [formDate, setFormDate] = useState(new Date());
  const [toDate, setToDate] = useState(prevesDate);

  const [myCommission, setMyCommission] = useState() 
  const [mComms, setMComms] = useState();
  const [sComms, setSComms] = useState();
  const [cComms, setCComms] = useState();
  const [tComms, setTComms] = useState();

  const initialValues = {
    FromDate: moment(toDate).format("MM/DD/YYYY") || "",
    ToDate: moment(formDate).format("MM/DD/YYYY") || "",
  }
  // const validationSchema = yup.object({
  //   FromDate: yup.string().required(""),
  //   ToDate: yup.string().required(""),
  // })
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: (values) => { 
      values.FromDate = toDate ? moment(toDate).format("MM/DD/YYYY") : ""
      values.ToDate = formDate ? moment(formDate).format("MM/DD/YYYY") : ""
      GetMyCommision(values)
    },
  })
  const GetMyCommision = (values) => { 
    authAxios({
      method: "POST",
      url: `${baseURL}/User/GetMyCommision`,
      data: values
    }).then(function (res) { 
      setMyCommission(res?.data?.message); 
      let mtotal = 0
      let stotal = 0
      let ctotal = 0
      let ttotal = 0 
      res?.data?.message?.myCommisionDataLists?.map((d, k) => { 
        mtotal = mtotal + Number(d?.mComm)
        stotal = stotal + Number(d?.sComm)
        ctotal = ctotal + Number(d?.cComm)
        ttotal = ttotal + Number(d?.tComm) 
        setMComms(mtotal)
        setSComms(stotal)
        setCComms(ctotal)
        setTComms(ttotal) 
      })
      
      // setMComm(res?.data?.message)  
    }).catch(function(error){
      common.error(error);
    })
  }
  useEffect(() => {
    let postData = {
      FromDate: "",
      ToDate: ""
    }
    GetMyCommision(postData)
  }, [])
  
  return (
    <> 
      <div className="MyCommission">
        <div className="headingOne "> my commission </div>
        <div className="container"> 
          <form onSubmit={formik.handleSubmit}>
            <div className='myCommissionFilter Fwc'>
            <DatePicker placeholderText='mm/dd/yyyy' selected={toDate} onChange={(date) => {setToDate(date)
                formik.setFieldValue("ToDate", new Date(date).toLocaleDateString())
              }} />
              <DatePicker placeholderText='mm/dd/yyyy' selected={formDate} onChange={(date) => {
                setFormDate(date)
                formik.setFieldValue("FromDate", new Date(date).toLocaleDateString())
              }} /> 
              
              <button type='submit'> Search </button>
            </div>
          </form> 
          <div className="tableScroll">
            <table className="mb-3">
              <thead> 
                <tr className="secHeading">
                  <th>Date</th>
                  <th>M.Comm</th>
                  <th>S.Comm</th>
                  <th>C.Comm</th>
                  <th>T.Comm</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody> 
                {myCommission ? myCommission?.myCommisionDataLists?.map((data, key) => (
                  <tr key={key}> 
                    <td>{data?.date}</td>
                    <td align='center'>{data?.mComm}</td>
                    <td align='center'>{data?.sComm}</td>
                    <td align='center'>{data?.cComm}</td>
                    <td align='center'>{data?.tComm}</td>
                    <td>{data?.name}</td>
                  </tr>
                )) : null}

                <tr> 
                  <td><b>Total</b></td>
                  <td  align='center'><b>{mComms}</b></td>
                  <td  align='center'><b>{sComms}</b></td>
                  <td  align='center'><b>{cComms}</b></td>
                  <td  align='center'><b>{tComms}</b></td>
                  <td> </td>
                </tr> 
              </tbody> 
            </table> 
          </div>  
        </div> 
        <Link to="/dashboard" className="backBtn"> back to main menu </Link>
      </div>
    </>
  )
}

export default MyCommission