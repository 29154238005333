import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import authAxios from '../../services/authAxios'
import baseURL from '../../services/baseUrl'
import common from '../../services/comman'
import moment from 'moment'
import { Link } from 'react-router-dom'
import "./ledgers.scss";

const LedMatDetails = () => {
    const { state } = useLocation();
    const[abortcontroll, setAbortControl] = useState("")

    const [matchDetail, setMatchDetail] = useState(false);
    const [ledmatchDetail, setLedMatchDetail] = useState();

    const [ledgerDetail, setLedgerDetail] = useState(false);
    const GetMatchD = ((values) => {
        authAxios({
            method: "POST",
            url: `${baseURL}/AccountDetails/GetLedgerMatchDetails`,
            data: values
        }).then(function (res) {
            setMatchDetail(res?.data?.data) 
            let array = []
            res?.data?.data?.sessionBetsDataList?.map((d, k) => {
                if (d?.resultScore != "") {
                    array.push(d)
                    setLedMatchDetail(array)
                }
                return d
            })

        }).catch(function (error) {
            common.error(error);
        })
    })
    const GetLedgerD = ((values) => {
        authAxios({
            method: "POST",
            url: `${baseURL}/AccountDetails/GetCasinoMatchDetails`,
            data: values
        }).then(function (res) {
            setLedgerDetail(res?.data?.data)
        }).catch(function (error) {
            common.error(error);
        })
    })
    useEffect(() => {
        setAbortControl(true);
        if (state?.matchId && state?.sportId === "4") {
            let postData = { MatchId: state?.matchId || "" }
            
            if(abortcontroll) {
                GetMatchD(postData)
            }
            
            setMatchDetail(true)
        }
        if (state?.d?.matchId && state?.state?.sportId === "5") {
            let postData = { MatchId: state?.d?.matchId || "" }
            if(abortcontroll) {
                GetLedgerD(postData)
            } 
            setLedgerDetail(true)
        }
        return () => {
            setAbortControl({}); 
        } 
    }, [state, abortcontroll])

    return (
        <>
            <section className="ledgerDetailsWrap  ">
                {matchDetail &&
                    <div className='matchTable'>
                        {matchDetail?.bookBetsDataList?.length >= 1 ?
                            <>
                                <h6 className="headingOne "> MATCH BETS  </h6>
                                <div className="tableScroll  ">
                                    <table className=" ">
                                        <thead>
                                            <tr className="secHeading">
                                                <th>team</th>
                                                <th>won by</th>
                                                <th>date</th>
                                                <th>rate</th>
                                                <th>amount</th>
                                                <th>mode</th>
                                                <th>p&l</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {matchDetail?.bookBetsDataList?.map((d, k) => (
                                                <tr key={k}>
                                                    <td>{d?.team}</td>
                                                    <td>{d?.winner}</td>
                                                    <td>{d?.date}</td>
                                                    <td>{d?.rate}</td>
                                                    <td>{d?.amount}</td>
                                                    <td>{d?.type}</td>
                                                    <td className={`boxResult ${d?.pl?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>{d?.pl}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            : ""}
                        &nbsp;&nbsp;
                        <h6 className="headingTwo "> FANCY BETS </h6>
                        <div className="tableScroll">
                            <table className="">
                                <thead>
                                    <tr className="secHeading">
                                        <th>runner</th>
                                        <th>date</th>
                                        <th>rate</th>
                                        <th>result</th>
                                        <th>amount</th>
                                        <th>mode</th>
                                        <th>p&l</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ledmatchDetail?.map((dd, kk) => (
                                        <tr key={kk}>
                                            <td>{dd?.match}</td>
                                            <td>{dd?.date}</td>
                                            <td>{dd?.rate}</td>
                                            <td>{dd?.resultScore}</td>
                                            <td>{dd?.amount}</td>
                                            <td>{dd?.betType}</td>
                                            <td className={`boxResult ${dd?.pl?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>{dd?.pl}</td>
                                        </tr>
                                    ))}
                                    {/* 
                                    {matchDetail?.sessionBetsDataList?.map((dd,kk) => (
                                    <tr key={kk}>
                                        <td>{dd?.match}</td>
                                        <td>{dd?.date}</td>
                                        <td>{dd?.rate}</td>
                                        <td>{dd?.resultScore}</td>
                                        <td>{dd?.amount}</td>
                                        <td>{dd?.betType}</td>
                                        <td className={`boxResult ${dd?.pl?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>{dd?.pl}</td>
                                    </tr>    
                                    ))}  
                                    */}
                                </tbody>
                            </table>
                        </div>

                        <div className='moreDetail'>
                            <div className='boxTitle'> Match Plus Minus </div>
                            <div className={`boxResult ${matchDetail?.matchPM?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Lost {matchDetail?.matchPM}/- Coins. */}
                                You {matchDetail?.matchPM?.toString()?.charAt(0) == "-" ? <>Lost {matchDetail?.matchPM}</> : <>Won {matchDetail?.matchPM}</>} /- Coins.
                            </div>
                            <div className='boxTitle'> Fancy Plus Minus </div>
                            <div className={`boxResult ${matchDetail?.sessionPlusMinus?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Won {matchDetail?.sessionPlusMinus}/- Coins. */}
                                You {matchDetail?.sessionPlusMinus?.toString()?.charAt(0) == "-" ? <>Lost {matchDetail?.sessionPlusMinus}</> : <>Won {matchDetail?.sessionPlusMinus}</>} /- Coins.
                            </div>

                            <div className='boxTitle'> Total Commission </div>
                            <div className={`boxResult ${matchDetail?.totalCommission?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Won {matchDetail?.totalCommission}/- Coins */}
                                You {matchDetail?.totalCommission?.toString()?.charAt(0) == "-" ? <>Lost {matchDetail?.totalCommission}</> : <>Won {matchDetail?.totalCommission}</>} /- Coins.
                            </div>

                            <div className='boxTitle'> Mob. App. Charges </div>
                            <div className={`boxResult ${matchDetail?.mobileCharge?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Lost {matchDetail?.mobileCharge}/- Coins. */}
                                You {matchDetail?.mobileCharge?.toString()?.charAt(0) == "-" ? <>Lost {matchDetail?.mobileCharge}</> : <>Won {matchDetail?.mobileCharge}</>} /- Coins.
                            </div>

                            <div className='boxTitle'> Net Plus Minus </div>
                            <div className={`boxResult ${matchDetail?.netPlusMinus?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Won {matchDetail?.netPlusMinus}/- Coins. */}
                                You {matchDetail?.netPlusMinus?.toString()?.charAt(0) == "-" ? <>Lost {matchDetail?.netPlusMinus}</> : <>Won {matchDetail?.netPlusMinus}</>} /- Coins.
                            </div>
                        </div>
                    </div>
                }
                {ledgerDetail &&
                    <div className='casinoTable'>
                        <h6 className="headingOne"> casino BETS ({state?.state?.match})</h6>
                        <div className="tableScroll">
                            <table className=" ">
                                <thead>
                                    <tr className="secHeading">
                                        <th>date</th>
                                        <th>match id</th>
                                        <th>type</th>
                                        <th>bet placed</th>
                                        <th>amount</th>
                                        <th>rate</th>
                                        <th>result</th>
                                        <th>mode</th>
                                        <th>won or loss</th>
                                        <th>p&l</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ledgerDetail?.casinoBetsDataLists?.map((data, key) => (
                                        <tr key={key}>
                                            <td align='center'>{data?.date}</td>
                                            <td align='center'>{data?.matchID}</td>
                                            <td align='center'>{data?.type}</td>
                                            <td align='center'>{data?.betPlaced}</td>
                                            <td align='center'>{data?.amount}</td>
                                            <td align='center'>{data?.rate}</td>
                                            <td align='center'>{data?.result}</td>
                                            <td align='center'>{data?.mode}</td>
                                            <td align='center'>{data?.winLoss}</td>
                                            <td className={`boxResult ${data?.pl?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>{data?.pl}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='moreDetail'>
                            <div className='boxTitle'> Match Plus Minus   </div>
                            <div className={`boxResult ${ledgerDetail?.matchProfitLoss?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Lost {ledgerDetail?.matchProfitLoss}/- Coins. */}
                                You {ledgerDetail?.matchProfitLoss?.toString()?.charAt(0) == "-" ? <>Lost {ledgerDetail?.matchProfitLoss}</> : <>Won {ledgerDetail?.matchProfitLoss}</>} /- Coins.
                            </div>
                            <div className='boxTitle'> Total Commission </div>
                            <div className={`boxResult ${ledgerDetail?.totalCommission?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Won {ledgerDetail?.totalCommission}/- Coins */}
                                You {ledgerDetail?.totalCommission?.toString()?.charAt(0) == "-" ? <>Lost {ledgerDetail?.totalCommission}</> : <>Won {ledgerDetail?.totalCommission}</>} /- Coins.
                            </div>
                            <div className='boxTitle'> Mob. App. Charges </div>
                            <div className={`boxResult ${ledgerDetail?.mobileCharge?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Lost {ledgerDetail?.mobileCharge}/- Coins. */}
                                You {ledgerDetail?.mobileCharge?.toString()?.charAt(0) == "-" ? <>Lost {ledgerDetail?.mobileCharge}</> : <>Won {ledgerDetail?.mobileCharge}</>} /- Coins.
                            </div>
                            <div className='boxTitle'> Net Plus Minus </div>
                            <div className={`boxResult ${ledgerDetail?.netPlusMinus?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>
                                {/* You Won {ledgerDetail?.netPlusMinus}/- Coins. */}
                                You {ledgerDetail?.netPlusMinus?.toString()?.charAt(0) == "-" ? <>Lost {ledgerDetail?.netPlusMinus}</> : <>Won {ledgerDetail?.netPlusMinus}</>} /- Coins.
                            </div>
                        </div>
                    </div>
                }

                {
                state?.sportId == 4 ? <Link to="/Ledgers" className="backBtn" state={state?.state}> back to list </Link> : 
                <Link to="/casinoDetails" className="backBtn" state={state?.state}> back to list </Link>
                }
                
            </section>
        </>
    )
}

export default LedMatDetails