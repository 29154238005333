import React from 'react'
import { useState, useEffect } from 'react'; 
import { useLocation } from 'react-router';
import authAxios from '../../services/authAxios';
import baseURL from '../../services/baseUrl';
import common from '../../services/comman'; 
import moment from 'moment';
import { Link } from 'react-router-dom'; 
const CasinoDetails = () => {
    const { state } = useLocation()
    const[abortcontroll, setAbortControl] = useState("")
    const [casinoDetails, setCasinoDetails] = useState([]);
    const GetUserLedgerD = ((values) => {  
        authAxios({
            method: "POST",
            url: `${baseURL}/AccountDetails/GetLedgerCasinoMatchList`,
            data: values
        }).then(function (res) { 
            setCasinoDetails(res?.data?.data); 
        }).catch(function (error) {
            common.error(error);
        }) 
    })
    useEffect(() => {
        setAbortControl(true);

        if (state?.date && state?.description) {
            let postData = {
                Competition: state?.match ||  "", 
                Date: moment(state?.date).format('MM/DD/YYYY') || ""
            }
            if(abortcontroll) {
                GetUserLedgerD(postData)
            }
        }
        return () => {
            setAbortControl({}); 
        } 
    }, [state, abortcontroll])

    return (
        <>
        <section className="casinoDetailsWrap">
            {/* <div className="container"></div> */}
            {/* <h6 className="headingOne"> MY LEDGER </h6> */}
            <div className="tableScroll">
            <table className="mb-3">
                <thead> 
                    <tr>
                        <th colSpan='4' style={{textAlign:'center', backgroundColor: '#3c7c88', color:'#fff', border: '0px solid transparent', padding: '4px' }}> MY LEDGER </th>
                    </tr>
                    <tr className="secHeading">
                        <th>description </th>
                        <th>won by</th>
                        <th>won</th>
                        <th>lost</th>
                    </tr>
                </thead>
                <tbody>
                    {casinoDetails?.ledgerCasinoMatchDatasLists?.map((d, k) => (
                        <tr key={k}>
                            <td align='left'><Link to="/LedMatDetails" state={{d:d, state:state } }>{d?.description}</Link></td>
                            <td align='center'>{d?.wonBy}</td>
                            <td align='center'>{d?.won}</td>
                            <td align='center' className={`boxResult ${d?.lost?.toString()?.charAt(0) == "-" ? "red" : "blue"}`}>{d?.lost}</td> 
                        </tr>    
                    ))}
                </tbody>
            </table>
            </div>
            <Link to="/Ledgers" className="backBtn"> back to Main Menu  </Link> 
        </section>
        </>
    )
}
export default CasinoDetails;