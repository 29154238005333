import React from 'react' 
import authAxios from '../../services/authAxios';
import baseURL from '../../services/baseUrl';
import common from '../../services/comman';
import { useState, useEffect } from 'react'; 
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const UserLiveBetHistory = () => {
  const[abortcontroll, setAbortControl]=useState("")   

  const sevdayPre = new Date(common.minuDay(new Date(), 15))
  const prevesDate = new Date(sevdayPre)
  const [userLBH, setUserLBH] = useState()
  const [startDate, setStartDate] = useState(prevesDate)
  const [endDate, setEndDate] = useState(new Date) 
  const [gameType, setGameType] = useState()
  const [statusChange, setStatusChange] = useState()
  const [searchTerm, setSearchTerm] = useState("");
  const [currentItems, setCurrentItems] = useState([]); 
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTotalItems, setCurrentTotalItems] = useState([]); 

  const getStatus = (e) => {
    setStatusChange(e.target.value)
  }
  const getGameType = (e) => {
    setGameType(e.target.value)
  } 
  const initialValues = {
    FromDate: moment(startDate).format("MM/DD/YYYY") || "",
    ToDate: moment(endDate).format("MM/DD/YYYY") || "",
    GameType: "All" || "",
    Status: "0" || "",
    Action: "2"
  } 
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {  
        values.FromDate = startDate ? moment(startDate).format("MM/DD/YYYY") : "" 
        values.ToDate = endDate ? moment(endDate).format("MM/DD/YYYY") : "" 
        values.GameType = gameType ? gameType.toString() : "All"  
        values.Status = statusChange ? statusChange.toString() : "0" 
        values.Action = "2"
      UserLiveBetHistory(values)
    }
  }) 
  const UserLiveBetHistory = (values) => { 
    authAxios({
      method: "POST",
      url: `${baseURL}/User/UserLiveBetHistory`,
      data: values
    }).then(function (res) { 
      setUserLBH(res?.data?.message); 
    }).catch(function(error){
      common.error(error);
    })
  }
  useEffect(() => {
    setAbortControl(true);

    let postData ={ 
      FromDate: startDate ? moment(startDate).format("MM/DD/YYYY") : "", 
      ToDate: endDate ? moment(endDate).format("MM/DD/YYYY") : "", 
      // FromDate: "", 
      // ToDate: "", 
      GameType: "All", 
      Status: "0", 
      Action: "2"
  }  
    if(abortcontroll){
      UserLiveBetHistory(postData)
    } 
    return () => {
      setAbortControl({}); 
    }  
  }, [abortcontroll])

  const handleChangeSearchTerm = (e) => {
    setSearchTerm(e.target.value)
  } 

  /*=== ===*/ 
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const itemsPerPage = 10;
  const startIndex = currentPage * itemsPerPage;
  useEffect(() => {
    let filterData;
    if (searchTerm != "") {
      filterData = userLBH?.myLiveBetHistoryDataLists?.filter((data, kk) => { 
      if (data?.betType?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          data?.client?.toLowerCase().includes(searchTerm?.toLowerCase())  ||
          data?.dateCreation?.toLowerCase().includes(searchTerm?.toLowerCase()) || 
          data?.description?.toLowerCase().includes(searchTerm?.toLowerCase()) || 
          data?.liability?.toLowerCase().includes(searchTerm?.toLowerCase()) || 
          data?.odds?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          data?.pl?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          data?.selection?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          data?.stack?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          data?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          data?.type?.toLowerCase().includes(searchTerm?.toLowerCase())
      ){
          return data
        }})  
    } else {
      filterData = userLBH?.myLiveBetHistoryDataLists;
    } 
    setCurrentTotalItems(filterData)
    filterData = filterData?.slice(startIndex, startIndex + itemsPerPage)
    setCurrentItems(filterData) 
  }, [userLBH,startIndex, searchTerm])
  
  
  return (
    <>
      <section className="userBetHistory">
      <h6 className="headingOne "> client live game bets report  </h6>
      <div className="container"> 
        <form onSubmit={formik.handleSubmit}>
          <div className='filterWrap'>
            <DatePicker placeholder="mm/dd/yyyy" selected={startDate} onChange={((date) => {setStartDate(date)})} />
            <DatePicker placeholder="mm/dd/yyyy" selected={endDate} onChange={((date) => {setEndDate(date)})} />
            <input type='text' placeholder='User' id="standard-basic" label="Search:" variant="standard" className="handlesearchterm"
                value={searchTerm} 
                onChange={handleChangeSearchTerm}
            /> 
            <select onChange={(eStates) => getGameType(eStates)}>
              {/* <option value="All" selected="selected">All Game</option> */}
              <option value="All">All Game</option>
              <option value="t20">Live T20</option>
              <option value="ab2">Andar bahar</option>
              <option value="oneday">One Day</option>
              <option value="lucky7">7 up &amp; Down</option>
              <option value="CARD32A">32 cardA</option>
              <option value="dt2020">Dragon Tiger</option>
              <option value="test">Test Teenpatti</option>
              <option value="lucky7B">Lucky7B</option>
              <option value="BOLLYWOODCASINO">Bollywood Casino</option>
              <option value="AMARAKBARANTHONY">Amar Akbar Anthony</option>
              {/* <option value="card32">32 cards casino</option> */}
              {/* <option value="dt20">Dragon Tiger</option> */}
              <option value="test">Test Teenpatti</option>
            </select>
            <select onChange={(eStates) => getStatus(eStates)}>
              <option value="0"> Open </option>
              <option value="1"> settle </option>
            </select>
            <button type='submit'> <i className="fa fa-filter"></i> Filter </button>
          </div>
        </form>
        <div className="tableScroll">
          <table className="mb-3">
            <thead> 
              <tr className="secHeading">
                <th>S.No.</th>
                <th>Client</th>
                <th>Description</th>
                <th>Selection</th>
                <th>Type</th>
                <th>Odds</th>
                <th>Stack</th>
                <th>Date</th>
                <th>P_L	</th> 
                <th>Liability</th>
                <th>Bet type</th>
                <th>Status</th> 
              </tr>
            </thead>
            <tbody>  
              {/* {userLBH?.myLiveBetHistoryDataLists?.map((d, k) => (   */}
              {currentItems?.map((d, k) => ( 
                <tr key={k}>
                  <td>{k+1}</td>
                  <td>{d?.client}</td>
                  <td>{d?.description}</td>
                  <td>{d?.selection}</td>
                  <td>{d?.type}</td>
                  <td>{d?.odds}</td>
                  <td>{d?.stack}</td>
                  <td>{d?.dateCreation}</td>
                  <td>{d?.pl}</td> 
                  <td>{d?.liability} </td>
                  <td> {d?.betType}</td>
                  <td>{d?.status}</td> 
                </tr>
              ))}
            </tbody>
          </table> 
        </div> 
      </div> 
      {/* */}
      <div className='my-3'>
        <ReactPaginate 
          pageCount={Math.ceil(currentTotalItems?.length / itemsPerPage)}
          onPageChange={handlePageChange}
          initialPage={currentItems}
          breakClassName="ellipsis"
          containerClassName="pagination"
          pageClassName="page-item"
          activeClassName="active"
        />  
      </div>
      <Link to="/dashboard" className="backBtn"> back to main menu </Link>
    </section>
    </>
  )
}

export default UserLiveBetHistory