import React, { useState, useEffect } from 'react' 
import authAxios from '../../services/authAxios'
import common from '../../services/comman'
import baseURL from '../../services/baseUrl' 
import { useFormik } from 'formik'
import DatePicker from "react-datepicker";
import * as yup from "yup";
import moment from 'moment' 
import ReactPaginate from 'react-paginate'; 
import { Link } from 'react-router-dom'
const UserBetHistory = () => { 
  const sevdayPre = new Date(common.minuDay(new Date(), 15)) 
  const prevesDate = new Date(sevdayPre)
  const [userBetHistory, setUserBetHistory] = useState(); 
  const [startDate, setStartDate] = useState(prevesDate);
  const [endDate, setEndDate] = useState(new Date); 
  const [statusChange, setStatusChange] = useState("0"); 
  const [changeAction, setChangeAction] = useState("1");
  const[abortcontroll, setAbortControl]=useState("")   


  const initialValues = { 
    searchData:"",
    Status: statusChange.toString() || "0",
    Action: changeAction.toString() || "1",
    FromDate: moment(startDate).format('MM/DD/YYYY') || "", 
    ToDate: moment(endDate).format('MM/DD/YYYY') || "", 
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      values.Status = statusChange.toString() || "0"
      values.Action = changeAction.toString() || "1"
      values.FromDate = startDate ? moment(startDate).format('MM/DD/YYYY') : ""
      values.ToDate = endDate ? moment(endDate).format('MM/DD/YYYY') : ""
      UserBetHistory(values)
    }
  })
  const UserBetHistory = (values) => {
    authAxios({
      method: "POST",
      url: `${baseURL}/User/UserBetHistory`,
      data: values
    }).then(function (res) {
      setUserBetHistory(res?.data?.message) 
    }).catch(function (error) {
      common.error(error)
    })
  }
  useEffect(() => {
    setAbortControl(true);
    let postData = { 
      Status: statusChange.toString() || "0",
      Action: changeAction.toString() || "1",
      FromDate: moment(startDate).format('MM/DD/YYYY') ||"",
      ToDate: moment(endDate).format('MM/DD/YYYY') || ""
    }
    if(abortcontroll){
      UserBetHistory(postData)
    } 
    return () => {
      setAbortControl({}); 
    } 
  }, [changeAction, abortcontroll]) 

  // useEffect(() => {  
  //       let postData = {
  //         Status: statusChange.toString() || "0",
  //         Action: changeAction.toString() || "1",
  //         FromDate: moment(startDate).format('MM/DD/YYYY') ||"",
  //         ToDate: moment(endDate).format('MM/DD/YYYY') || ""
  //       }
  //       UserBetHistory(postData)
      
  // }, [changeAction]) 
  

  const getStatus = (eStates) =>{
    setStatusChange(eStates.target.value)
  } 

  /* FILTER */
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [currentTotalItems, setCurrentTotalItems] = useState([]); 
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const itemsPerPage = 10;
  const startIndex = currentPage * itemsPerPage; 
  // let currentItems = userBetHistory?.myUserBetHistoryDataLists.slice(startIndex, startIndex + itemsPerPage); 
  const handleChangeSearchTerm = (e) => {  
    setSearchTerm(e.target.value);
  };
  useEffect(() => {  
    let finalitems; 
    if (searchTerm != "") {
      finalitems = userBetHistory?.myUserBetHistoryDataLists?.filter((data, kk) => { 
        if (
          data?.betType?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.client?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.dateCreation?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.description?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.liability?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.odds?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.pl?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.selection?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.stack?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.status?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
          data?.type?.toLowerCase()?.includes(searchTerm?.toLowerCase())  
        ) {
          return data;
        }
      })
    } else {
      finalitems = userBetHistory?.myUserBetHistoryDataLists; 
    } 
    setCurrentTotalItems(finalitems) 
    finalitems = finalitems?.slice(startIndex, startIndex + itemsPerPage)
    setCurrentItems(finalitems)  
  }, [userBetHistory, startIndex, searchTerm])

  return (
    <section className="userBetHistory  ">
      <h6 className="headingOne"> client live game bets report </h6> 
      <div className="container">  
        <form onSubmit={formik.handleSubmit}>
          <div className='filterWrap'> 
            <DatePicker placeholder="mm/dd/yyyy" selected={startDate} onChange={((date) => {
              setStartDate(date) 
              // formik.setFieldValue("FromDate", moment(date).format('MM/DD/YYYY'))
            })} />
            <DatePicker placeholder="mm/dd/yyyy" selected={endDate} onChange={((date) => {
              setEndDate(date)
              // formik.setFieldValue("ToDate", moment(date).format('MM/DD/YYYY'))
            })} /> 
            <input type='text' placeholder='Search'
              id="standard-basic"
              label="Search:"
              variant="standard"
              className="handlesearchterm" 
              value={searchTerm}
              onChange={handleChangeSearchTerm}
            />
            {/* 
              <input type='text' placeholder='Search'
              {...formik.getFieldProps("searchData")} />
              {formik.touched.searchData && formik.errors.searchData ? (<div className='ErrorMessage'>{formik.errors.searchData}</div>) : null} 
            */}
              <select onChange={(eStates) => getStatus(eStates)}>
                <option value="0"> Open </option>
                <option value="1"> settled </option>
              </select> 
            <button type='submit'> <i className="fa fa-filter"></i> Filter </button>
          </div>
        </form> 
        <div className='buttonGroup'>
          <button className={changeAction == 1 ? "bgHover" : ""} onClick={()=> setChangeAction(1)}> All </button>
          <button className={changeAction == 4 ? "bgHover" : ""} onClick={()=> setChangeAction(4)}> Cricket </button>
          <button className={changeAction == 3 ? "bgHover" : ""} onClick={()=> setChangeAction(3)}> Fancy </button>  
        </div>
        <div className="tableScroll">
          <table className="mb-3">
            <thead> 
              <tr className="secHeading">
                <th>Client</th>
                <th>Description</th>
                <th>Selection</th>
                <th>Type</th>
                <th>Odds</th>
                <th>Date</th>
                <th>P_L</th>
                <th>Liability</th>
                <th>Bet type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>  
              {currentItems ? currentItems?.map((d, k) => ( 
                <tr key={k}>
                  <td>{d?.client}</td>
                  <td>{d?.description}</td>
                  <td>{d?.selection}</td>
                  <td>{d?.type}</td>
                  <td>{d?.odds}</td>
                  <td>{d?.dateCreation}</td>
                  <td>{d?.pl}</td>
                  <td>{d?.liability}</td>
                  <td>{d?.betType}</td>
                  <td>{d?.status}</td>
                </tr>
              )) : null}
            </tbody>
          </table> 
        </div> 
        <div className='mb-3'> 
        <ReactPaginate 
          pageCount={Math.ceil(currentTotalItems?.length / itemsPerPage)}
          onPageChange={handlePageChange}
          initialPage={currentItems}
          breakClassName="ellipsis"
          containerClassName="pagination"
          pageClassName="page-item"
          activeClassName="active"
          /> 
        </div>
      </div>
      <Link to="/dashboard" className="backBtn"> back to main menu </Link>
    </section>
  )
}

export default UserBetHistory