import React, { lazy, Suspense } from "react";
// import { Spinner } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import MyCommission from "../pages/user/myCommission";
import UserProfitLoss from "../pages/user/userProfitLoss";
import UserMyLedger from "../pages/user/userMyLedger";
import UserLiveBetHistory from "../pages/user/userLiveBetHistory";
import UserBetHistory from "../pages/user/userBetHistory";
import CompletedBets from "../pages/completedBets";
import common from "../services/comman";
import { MenuName } from "../services/menuName"; 
import LedgerDetails from "../pages/ledgers/ledMatDetails";
import CasinoDetails from "../pages/ledgers/casinoDetails";
import LedMatDetails from "../pages/ledgers/ledMatDetails";
import Setting from "../pages/setting";

// import SettingDash from "../pages/user/settingDash";
const Header = lazy(() => import("./header"));
const Home = lazy(() => import("../pages/home/home"));
const LoginClient = lazy(() => import("../pages/login/loginClient"));
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
/* ======================= DASHBOARDS START  ======================= */
const Matches = lazy(() => import("../pages/matches/matches"));
const Profile = lazy(() => import("../pages/profile/profile"));
const Statement = lazy(() => import("../pages/statement/statement"));
const ChangePassword = lazy(() =>import("../pages/changePassword/change-password"));
const FreeGames = lazy(() => import("../pages/freeGames.js/freeGames"));
const Rules = lazy(() => import("../pages/rules/rules"));
const Ledgers = lazy(() => import("../pages/ledgers/ledgers"));
const Games = lazy(() => import("../pages/casino/games"));
const SettingDash = lazy(() => import("../pages/user/settingDash"));
const SportGame = lazy(() => import("../pages/matches/sportGame"));
/* ======================= DASHBOARDS END ======================= */
const TeenPatti2020 = lazy(() => import("../pages/casino/teenPatti2020"));
const OneDayTeenpatti = lazy(() => import("../pages/casino/oneDayTeenpatti"));
const TestTeenPatti = lazy(() => import("../pages/casino/TestTeenPatti"));
const AndarBahar = lazy(() => import("../pages/casino/andarBahar"));
const Lucky7 = lazy(() => import("../pages/casino/lucky7"));
const AmarAkbarAnthony = lazy(() => import("../pages/casino/amarAkbarAnthony"));
const Lucky7B = lazy(() => import("../pages/casino/Lucky7B"));
const DragomTiger2020 = lazy(() => import("../pages/casino/DragonTiger2020"));
const Cards32A = lazy(() => import("../pages/casino/Cards32A"));
const BollywoodCasino = lazy(() => import("../pages/casino/BollywoodCasiono"));
const VirtualCasino = lazy(() => import("../pages/casino/virtualCasino"));
// const DragonTigerT20 = lazy(() => import("../pages/casino/TestTeenPatti"));
const Loader = () => {
  return (
    <>
      <div className="loader">
          <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
          </div>
      </div> 
    </>
  );
}; 
const Layout = (props) => {
  return (
    <> 
      {/* <Suspense fallback={loader}> */}
      <Suspense fallback={<Loader /> }>
        <Header />
        <Routes>
          {/* <Route exact path="/" element={<Rules />} /> */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/login" element={<LoginClient />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          {/* ======================= DASHBOARDS START ======================= */}
          <Route exact path="/matches" element={<Matches />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/statement" element={<Statement />} />
          <Route exact path="/Change_password" element={<ChangePassword />} />
          <Route exact path="/freegames" element={<FreeGames />} />
          <Route exact path="/rules" element={<Rules />} />
          <Route exact path="/ledgers" element={<Ledgers />} />
          <Route exact path="/LedMatDetails" element={<LedMatDetails />} />
          <Route exact path="/casinoDetails" element={<CasinoDetails />} />
          
          <Route exact path="/games" element={<Games />} />
          <Route exact path="/settingall" element={<SettingDash />} />
          {/* ======================= DASHBOARDS END ======================= */}
          <Route exact path="/sportGame/:Matchid/:eventId/:marketId" element={<SportGame />} />
          
          {/* ======================= CASINO START======================= */}
          <Route exact path="/games/liveandarbahar2" element={<AndarBahar />} />
          <Route exact path="/games/aaa" element={<AmarAkbarAnthony />} />
          <Route exact path="/games/lucky7A" element={<Lucky7 />} />
          <Route exact path="/games/teenpatti-t20" element={<TeenPatti2020 />} />
          <Route exact path="/games/oneday" element={<OneDayTeenpatti />} />
          <Route exact path="/games/testteenpatti" element={<TestTeenPatti />} />
          <Route exact path="/games/Lucky7BLatest" element={<Lucky7B />} />
          <Route exact path="/games/dragonTiger2020" element={<DragomTiger2020 />} />
          <Route exact path="/games/cards32A" element={<Cards32A />} />
          <Route exact path="/games/BollywoodCasino" element={<BollywoodCasino />} />
          {/* ======================= CASINO END======================= */} 
          {/* ======================= USER START======================= */}
          <Route exact path="/UserLiveBetHistory" element={<UserLiveBetHistory />} />
          <Route exact path="/UserMyLedger" element={<UserMyLedger />} />
          <Route exact path="/MyCommission" element={<MyCommission />} />
          <Route exact path="/UserProfitLoss" element={<UserProfitLoss />} /> 
          <Route exact path="/UserBetHistory" element={<UserBetHistory />} /> 
          {/* ======================= USER END======================= */}
          <Route exact path="/Setting" element={<Setting />} /> 
          <Route exact path="/virtualCasino" element={<VirtualCasino/>} /> 
          
          {/* <Route exact path="/Completed-bets/:Matchid" element={<CompletedBets />} />  */}
          <Route exact path="/Completed-bets/:Matchid/:eventId/:marketId" element={<CompletedBets />} /> 
          
        </Routes>
      </Suspense>
    </>
  );
};
export default Layout;