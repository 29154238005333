import React from "react";
import { UserContext, UserProvider } from "./context/loginContext";  
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import 'react-toastify/dist/ReactToastify.css';

import "react-datepicker/dist/react-datepicker.css";
import "./asstes/style.scss"; 
import Layout from "./includes/layout";
import LoginClient from "./pages/login/loginClient"; 
import { useEffect, useState } from "react"; 

const App = () => {
  // const token = localStorage.getItem('token') 
  
  return ( 
    <> 
      <UserProvider> 
        <UserContext.Consumer>
          {({ isLogin }) => (isLogin ? <Layout /> : <LoginClient />)}
        </UserContext.Consumer>
      </UserProvider>
    </>
  );
};
export default App;