import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import baseURL from "./baseUrl";
import common from "./comman";
const authAxios = axios.create({
  baseURL: baseURL,
});
const bodyAnimation = (type, loader) => {
  if (type == "add" && loader) {
    document.body.className = "loadingPage";
  } else if (type == "remove" && loader) {
    document.body.className = document.body.className.replace(
      "loadingPage",
      ""
    );
  }
};
const errorshow = (err) => {
  let errHndle = err.response != undefined ? true : false;
  if (errHndle == true && err.response.status === 401) {
    let urlParams = new URLSearchParams(window.location.search);
    let ssr = urlParams.get("ssr");
    if (ssr != 1) {
      reactLocalStorage.clear("token");
      window.location.href = "/";
    }
  }
};


authAxios.interceptors.request.use(
  (config) => {
    bodyAnimation("add", config.loader);
    let token = reactLocalStorage.get("token");
    let headers = {
      baseURL: baseURL, 
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }; 
      // if (config.data) {
        // let postData = config.data; 
        // config.data = common.encrypt(postData);
        // config.data = common.decrypt(postData);
      // }
    config.headers = headers;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


authAxios.interceptors.response.use(
  (response) => {
    bodyAnimation("remove", response.config.loader);
    return response;
  },
  (error) => {
    var errorType = error.response != undefined ? error.response.config.loader : true;
    bodyAnimation("remove", errorType);
    errorshow(error);
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  undefined,
  function axiosRetryInterceptor(err) {
    var errorType = err.response != undefined ? err.response.config.loader : true;
    bodyAnimation("remove", errorType);
    errorshow(err);
    return Promise.reject(err);
  }
);
export default authAxios;